<template>
  <b-modal
    visible
    scrollable
    centered
    size="xl"
    title=" Запись пациента в очередь"
    hide-footer
    @hidden="onClose"
  >
    <b-container
      fluid="xl"
    >
      <div class="crm-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-block w-100">
            <div class="font-weight-bold mb-2">
              Куда записываем пациента
            </div>
            <v-select
              v-model="queue"
              :options="queueList"
              :reduce="queue => queue.id"
              label="name"
              placeholder="Куда записываем пациента"
            />

            <div
              v-if="errors.queue"
              class="text-danger mt-2 small"
            >
              {{ errors.queue[0] }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="mb-3">
          <div class="d-flex">
            <div class="w-25 mr-4">
              <div class="font-weight-bold mb-2">
                Тип связи
              </div>
              <multiselect
                v-model="checkup.communicationType"
                class="multiselect-custom"
                :multiple="false"
                :hide-selected="true"
                :close-on-select="true"
                label="name"
                track-by="type"
                select-label="Выбрать"
                selected-label="Выбрано"
                deselect-label="Нажмите, чтобы удалить"
                placeholder="Выберите тип связи"
                :options="selectConnection"
                :searchable="false"
              />
            </div>
            <div>
              <div class="font-weight-bold mb-2">
                Файлы
              </div>
              <div>
                <b-button
                  size="sm"
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  @click="openDownloadFileModal"
                >
                  Добавить файлы
                </b-button>
                <transition-group
                  name="list-download-items"
                  tag="p"
                >
                  <div
                    v-for="(item, index) in fileNames"
                    :key="item.guid"
                  >
                    <div
                      class="mt-1 d-flex align-items-center mb-1"
                    >
                      <span class="mr-2">{{ item.title }}</span>
                      <v-button
                        label="X"
                        size="xs"
                        type="outline"
                        variant="accent_alt"
                        @click="deleteDownloadFile(index)"
                      />
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
          </div>

          <template v-if="accountType === 0">
            <div class="d-flex align-items-center">
              <b-form-input
                v-if="search.field === 'phone'"
                v-model="search.filter.PhoneNumber"
                type="search"
                size="sm"
                :disabled="search.isLoading"
                placeholder="Введите номер телефона"
                class="border"
                @keyup.native.enter="onClickSearch"
              />
              <b-form-input
                v-if="search.field === 'fio'"
                v-model="search.filter.SearchFIO"
                type="search"
                size="sm"
                :disabled="search.isLoading"
                placeholder="Введите ФИО"
                class="border"
                @keyup.native.enter="onClickSearch"
              />
              <b-form-input
                v-if="search.field === 'policy'"
                v-model="search.filter.PolicyNumber"
                type="search"
                size="sm"
                :disabled="search.isLoading"
                placeholder="Введите номер полиса"
                class="border"
                @keyup.native.enter="onClickSearch"
              />
              <b-button
                id="popover-filter"
                v-b-tooltip.hover
                variant="light"
                size="sm"
                :disabled="search.isLoading"
                :type="$const.PRIMARY_BUTTON"
                class="border border-primary p-1 ml-2"
                title="Дополнительные фильтры"
              >
                <b-icon icon="filter" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                variant="light"
                size="sm"
                class="border border-success p-1 ml-2"
                title="Поиск"
                :disabled="search.isLoading"
                :type="$const.PRIMARY_BUTTON"
                @click="onClickSearch"
              >
                <b-spinner
                  v-if="search.isLoading"
                  variant="primary"
                  small
                />
                <b-icon
                  v-else
                  icon="search"
                />
              </b-button>
            </div>

            <div
              v-if="search.error"
              class="text-danger mb-2 small"
            >
              {{ search.error }}
            </div>
            <div
              v-if="errors.patientId"
              class="text-danger mt-2 small"
            >
              {{ errors.patientId[0] }}
            </div>

            <div
              v-if="search.patients && search.patients.length"
              class="mt-3"
            >
              <div class="patients d-flex">
                <PatientInfo
                  v-for="patient in search.patients"
                  :key="patient.id"
                  :patient="patient"
                  :active="patient.id === checkup.patientId"
                  class="mr-2"
                  @click.native="setPatient(patient)"
                />
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Текст обращения
          <div class="small">
            (опционально)
          </div>
        </div>

        <b-form-textarea
          v-model="checkup.body"
          rows="3"
          max-rows="6"
        />
      </div>

      <div class="crm-wrapper-buttons">
        <b-button
          v-if="accountType === 0"
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="onClickSave"
        >
          Записать
        </b-button>
      </div>
    </b-container>
    <b-popover
      target="popover-filter"
      triggers="click focus blur"
    >
      <div class="popover-inner p-2">
        <div>
          <div class="mb-3">
            <div class="mb-2 font-weight-bold">
              Поиск по
            </div>

            <b-form-radio-group
              v-model="search.field"
              :options="SEARCH_FIELDS"
              value-field="alias"
              text-field="title"
              class="mb-2"
              @change="changeSearchField"
            />
          </div>
          <div class="mb-3">
            <div class="mb-2 font-weight-bold">
              Тип полиса
            </div>

            <b-form-radio-group
              v-model="search.filter.PatientType"
              :options="POLICY_TYPES"
              value-field="id"
              text-field="title"
              class="mb-2"
              @change="changeSearchField"
            />
          </div>
        </div>
      </div>
    </b-popover>
  </b-modal>
</template>

<script>
import { formatISO } from '@evd3v/date-fns';

import { VButton } from '@doctis.front/doctis.designsystem';

import PatientInfo from '@/components/Schedule/Modals/ScheduleMakeAnAppointmentModal/PatientInfo';

import { validationMixin } from 'vuelidate';

import {
  POLICY_TYPES,
  SEARCH_FIELDS,
} from '@/helpers/consts';

import { phoneMixins } from '@/mixins/phoneMixins';
import { dateWithoutTime } from '@/helpers/utils';
import { showValidationErrorMessage } from '@/helpers/messages';

import { createPatientQueueAndIdSchema } from '@/validation/zod/patient/patient.validation.js';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'PatientAppointmentModal',
  components: {
    PatientInfo,
    VButton,
  },
  mixins: [validationMixin, phoneMixins],
  props: {
    patientDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isSaving: false,
      checkValidations: false,
      SEARCH_FIELDS,
      POLICY_TYPES,
      accountType: 0,
      documents: [],
      checkup: {
        middleName: '',
        firstName: '',
        lastName: '',
        birthDate: null,
        sex: null,
        phoneNumber: '',
        body: '',
        patientId: null,
        parentId: null,
        communicationType: '',
      },
      selectConnection: [
        { name: 'Текст', type: 'text' },
        { name: 'Аудио', type: 'audio' },
        { name: 'Видео', type: 'video' },
      ],
      fileNames: [],
      search: {
        isLoading: false,
        filter: {
          PhoneNumber: null,
          SearchFIO: null,
          PolicyNumber: null,
          PatientType: 2,
        },
        field: 'phone',
        patients: [],
        error: null,
      },
      queue: null,
      queueList: [],
      selectedPerson: null,
      errors: {},
    };
  },
  computed: {
  },
  async mounted() {
    this.queueList = await this.$store.dispatch(this.$types.DUTY_QUEUE_LIST_FETCH);
    if (this.patientDetails.phoneNumber && this.patientDetails.phoneNumber !== null) {
      this.search.filter.PhoneNumber = this.patientDetails.phoneNumber;
      this.onClickSearch();
    }
  },
  methods: {
    saveFilesCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        this.fileNames.push({
          title: fileObject.name,
          guid: fileObject.responseData?.guid,
        });
        return null;
      });
    },
    openDownloadFileModal() {
      uiService.showModal(MODALS.FILES_UPLOAD_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка файлов',
          saveFileCallback: this.saveFilesCallback,
        },
      });
    },
    deleteDownloadFile(index) {
      this.fileNames.splice(index, 1);
    },
    async onClickSave() {
      this.errors = {};

      const patientQueueAndIdSchema = createPatientQueueAndIdSchema(this.accountType);

      const validationResult = patientQueueAndIdSchema.safeParse({
        queue: this.queue,
        patientId: this.checkup.patientId,
      });

      this.checkValidations = true;

      if (!validationResult.success) {
        validationResult.error.errors.forEach((err) => {
          let errorMsg = err.message;
          if (errorMsg === 'Expected string, received null') {
            if (err.path[0] === 'queue') {
              errorMsg = 'Выберите куда записываем пациента';
            }
          }
          this.$set(this.errors, err.path[0], [errorMsg]);
        });
        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;

      try {
        const personObject = this.accountType === 0
          ? this.selectedPerson : {
            phoneNumber: this.checkup.phoneNumber,
            lastName: this.checkup.lastName,
            firstName: this.checkup.firstName,
            middleName: this.checkup.middleName,
            birthDate: this.checkup.birthDate ? dateWithoutTime(formatISO(this.checkup.birthDate)) : null,
            sex: this.checkup.sex,
          };

        const preparedFileNames = this.fileNames.map((item) => item.guid);

        const params = {
          queue: this.queue,
          communicationType: this.checkup.communicationType.type ? this.checkup.communicationType.type : 'Text',
          person: personObject,
          consultationBody: this.checkup.body,
          fileNames: preparedFileNames,
        };

        const consultationId = await this.$store.dispatch(this.$types.DUTY_APPOINTMENT_CREATE, params);

        if (consultationId) {
          const fileToken = await this.$store.dispatch(this.$types.SCHEDULE_FILETOKEN_FETCH, consultationId);

          const documentsId = this.documents.map((doc) => doc.guid);

          await this.$store.dispatch(this.$types.SCHEDULE_CHECKUP_ADD_DOCUMENTS, {
            consultationId,
            documentsId,
            fileToken,
          });
        }
      } finally {
        this.isSaving = false;
      }
      this.onClose();
    },
    changeSearchField() {
      this.search.filter.PhoneNumber = null;
      this.search.filter.SearchFIO = null;
      this.search.filter.PolicyNumber = null;
    },
    async onClickSearch() {
      this.checkup.patientId = null;

      try {
        this.search.isLoading = true;
        this.search.error = null;
        this.search.patients = [];

        const patients = await this.$store.dispatch(this.$types.SCHEDULE_PATIENTS_SEARCH, this.search.filter);
        this.search.patients = patients;
      } catch (error) {
        console.error(error);
      } finally {
        this.search.isLoading = false;

        if (this.search.patients.length === 0) {
          if (this.search.field === 'phone') {
            this.search.error = 'Пользователь с таким номером телефона не найден.';
          }

          if (this.search.field === 'fio') {
            this.search.error = 'Пользователь с таким ФИО не найден.';
          }

          if (this.search.field === 'policy') {
            this.search.error = 'Пользователь с таким номером полиса не найден.';
          }
        }
      }
    },
    setPatient(patient) {
      this.selectedPerson = patient;

      if (this.checkup.patientId === patient.id) {
        this.checkup.patientId = null;
        this.checkup.parentId = null;
        return;
      }
      this.checkup.parentId = patient.parentId;
      this.checkup.patientId = patient.id;
    },
    saveFileCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        console.log(fileObject);
        this.documents.push({
          title: fileObject.name,
          guid: fileObject.responseData,
        });
        return null;
      });
    },
    onClickOpenFileModal() {
      uiService.showModal(MODALS.FILES_UPLOAD_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка документов',
          saveFileCallback: this.saveFileCallback,
          fileApi: 'File/fileServer',
        },
      });
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-form-field{
  &.mg-tp {
    margin-top: 20px;
  }
  &-search {
    margin-right: 0!important;
  }
}

::v-deep.crm-form-fields {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.crm-form-block {
  &.w-100{
    width: 100%;
  }
}

::v-deep.crm-radio-buttons {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .crm-radio-button {
    &:not(:last-child) {
      margin-bottom: 10px;
      margin-right: 35px;
    }
  }

  .crm-radio-input:checked + .crm-radio-text {
    color: $blue;
  }

  &.vertical {
    flex-direction: column;

    .crm-radio-button {
      &:not(:last-child) {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }
}

::v-deep.crm-button-search {
  margin-left: 10px;
  height: 40px;
  width: 40px;
  border-radius: 10px;

  .crm-icon-search path {
    fill: #fff;
  }
}

.search-error {
  margin-top: 10px;
  position: initial;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

::v-deep.patient-item {
  cursor: pointer;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  display: flex;

  &.active {
    border: 1px solid #6E88F3;
  }

  &:hover {
    border: 1px solid #6E88F3;
  }
}

::v-deep.crm-filter-popover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  .trigger {
    height: 100%;
  }

  .crm-icon-wrapper {
    cursor: pointer;
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;

    &:hover {
      svg {
        path {
          fill: $blue;
        }
      }
    }
  }
  &.open {
    .crm-icon-wrapper {
      svg {
        path {
          fill: $blue;
        }
      }
    }
  }
}

::v-deep.crm-filter-popover-tooltip {
  background: #FFFFFF;
  box-shadow: 0px 1px 5px #C4C4C4;
  border-radius: 10px;
}

::v-deep.crm-button {
  &.width-unset {
    width: unset;
    padding-left: 15px;
    padding-right: 15px;
    flex-shrink: 0;
  }
}
::v-deep.crm-icon-cross {
  margin-right: 15px;
  path {
    fill: $blue;
  }
}

::v-deep.crm-file-list {
  margin-bottom: 20px;

  .file {
    margin-top: 20px;
  }
}

::v-deep.custom-form-radio-group-account {
  margin-top: 11px;
  .custom-control-label {
    padding-top: 3px;
  }
}

::v-deep.custom-form-radio-group-gender {
  margin-top: 15px;
  .custom-control-label {
    padding-top: 3px;
  }
}

.patients {
  overflow: auto;
  max-width: 100%;
  padding-bottom: 20px;
}

.multiselect-custom {
  & ::v-deep {
    .multiselect__tags {
      height: auto;
      min-height: calc(1.5em + 0.5rem + 2px);
      padding: 2px 40px 0 8px;
      font-size: 0.875rem;
      line-height: 0.5;
      border-radius: 0.2rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
    }
    .multiselect__input {
      color: grey;
      line-height: unset;
      padding: unset;
      margin: unset;
      font-size: 14px;
      position: unset;
    }
    .multiselect__select:before {
      top: 85%;
    }
    .multiselect__select {
      width: 25px;
      height: 25px
    }
    .multiselect__placeholder {
      color: grey;
      font-size: 13.5px;
      margin-top: 9px;
      padding: 0;
    }
    .multiselect__option--highlight,
    .multiselect__option--highlight:after,
    .multiselect__tag {
      background: #007bff;
    }
    .multiselect__tag-icon:hover {
      background: #0063cd;
    }
    .multiselect__single {
      margin-bottom: 0px;
      padding-top: 2px;
    }
  }
}

.list-download-items-enter-active,
.list-download-items-leave-active {
  transition: all .7s;
}
.list-download-items-enter,
.list-download-items-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.slide-fade-enter-active {
  transition: all .7s ease;
}
.slide-fade-leave-active {
  transition: all .7s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
